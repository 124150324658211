<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
       <div class="box">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm m-t-20">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="model">
              <el-input v-model="ruleForm.model"></el-input>
            </el-form-item>
            <el-form-item label="所属主题" prop="model">
              <el-input v-model="ruleForm.theme"></el-input>
            </el-form-item>
            <el-form-item label="数据表" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择数据表">
                <el-option label="户籍信息表" value="户籍信息表"></el-option>
                <el-option label="车辆信息表" value="车辆信息表"></el-option>
                 <el-option label="新生儿信息表" value="新生儿信息表"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据集ID" prop="id">
              <el-table class="m-t-20"  :data="tableData" fit stripe>
                <el-table-column label="序号" type="index" />
                <el-table-column label="字段名称" prop="dd" />
                <el-table-column label="字段英文名" prop="theme" />
                <el-table-column label="规则">
                    <el-button type="primary"  @click="add()">+添加</el-button>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item class="btn">
              <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
               <el-button type="primary" @click="submitForm('ruleForm')">发布</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </el-form>
       </div>
    </div>
  </div>

 <el-dialog v-model="dialogAdd" title="添加规则">
    <el-table class="m-t-20"  :data="tableData1" fit stripe>
      <el-table-column type="selection" width="55" />
      <el-table-column label="序号" type="index" width="100"/>
      <el-table-column label="名称" prop="dd" />
      <el-table-column label="描述" prop="theme" />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addUsers()">确 定</el-button>
         <el-button @click="dialogAdd = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'dataModelAdd',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
      return {
        dialogAdd: false,
        tableData: [
              {
              dd: '姓名',
              theme: 'xm',
            },{
              dd: '身份证号',
              theme: 'sfzh',
            },{
              dd: 'xydm',
              theme: 'xb',
            },{
              dd: '民族',
              theme: 'mz',
            },
        ],
        tableData1: [
              {
              dd: '数据是否为空',
              theme: '数据是否为空',
            },{
              dd: '数据值范围',
              theme: '设置数据取值范围',
            },{
              dd: '符合某正则表达式',
              theme: '设置数据内容符合某正则表达式',
            }
        ],
        ruleForm: {
          name: '',
          model:'',
          theme:'',
          region: '',
          id:'',
          num:1
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          model: [
            { required: true, message: '请输入模型', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
        }
      };
    },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
     submitForm(formName) {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$router.push({
                  name: 'qualityModel'
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          },
    resetForm() {
      
    },
    add(){
      this.dialogAdd = true;
    },
    addUsers(){
      this.dialogAdd = false;
    }
  },
}
</script>

<style scoped>
  .box{
    padding: 0 5vw;
  }
  .box .btn :deep(.el-form-item__content) {
    text-align: center;
        margin: 0!important;
  }
</style>
